import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/bestpractice/bestpractice/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "practices-for-version-control"
    }}>{`Practices for Version Control`}</h1>
    <h2 {...{
      "id": "general-information-and-practices"
    }}>{`General Information and Practices`}</h2>
    <ul>
      <li parentName="ul">{`We use Git for version control which is a modern distributed version control system suitable for projects of any size.`}</li>
      <li parentName="ul">{`Source code repositories are hosted on GitHub, under the organization City-of-Helsinki.`}</li>
      <li parentName="ul">{`The branching model in use for projects is GitHub flow. Refer to section `}<a parentName="li" {...{
          "href": "#git-workflow"
        }}>{`Git Workflow`}</a>{` below for a more detailed explanation.`}</li>
      <li parentName="ul">{`Releases are versioned using `}<a parentName="li" {...{
          "href": "https://semver.org"
        }}>{`semantic versioning`}</a>{`.`}<ul parentName="li">
          <li parentName="ul">{`Note that "API changes" means e.g. breaking URL's that used to work!`}</li>
        </ul></li>
      <li parentName="ul">{`Document changes in a changelog that is called `}<inlineCode parentName="li">{`CHANGELOG.md`}</inlineCode>{` and resides at the root of your repository. `}<a parentName="li" {...{
          "href": "https://keepachangelog.com/en/1.0.0/"
        }}>{`Keep a Changelog`}</a>{` offers a good model for informing users about changes in a release.`}</li>
    </ul>
    <h3 {...{
      "id": "commits-pull-requests-and-messages"
    }}>{`Commits, Pull Requests and Messages`}</h3>
    <ul>
      <li parentName="ul">{`Commit as often as possible, but usually not when the code is broken (doesn't work)`}<ul parentName="li">
          <li parentName="ul">{`so try to arrange your changes into small self-contained and minimal commits that keep everything working`}</li>
        </ul></li>
      <li parentName="ul">{`Write commit messages in the following manner`}<ul parentName="li">
          <li parentName="ul">{`Mention the Jira issue key (KUVA-3, for instance) in the commit message`}<ul parentName="li">
              <li parentName="ul">{`A good place for the key is the beginning of the commit message title (ie. the first line of the commit message). This way the key will also be visible in short-format git logs.`}</li>
            </ul></li>
          <li parentName="ul">{`In the title, write a very short summary of `}<em parentName="li">{`what`}</em>{` was done`}</li>
          <li parentName="ul">{`In the commit message body, specify `}<em parentName="li">{`why`}</em>{` the change was done (what it will change as result) and why it was implemented in this way`}</li>
        </ul></li>
      <li parentName="ul">{`We have the Github Jira integration which does some magic linking if you mention issues in commit messages, PR's, branch names or Github issues.`}<ul parentName="li">
          <li parentName="ul">{`You can also use commit messages to update Jira issues as mentioned in Smart commits documentation.`}</li>
        </ul></li>
      <li parentName="ul">{`Arrange your work so that it's made in the smallest possible useful increments, and make pull requests out of those.`}<ul parentName="li">
          <li parentName="ul">{`Mention the Jira issue in the PR name.`}</li>
        </ul></li>
    </ul>
    <h3 {...{
      "id": "code-reviews"
    }}>{`Code Reviews`}</h3>
    <ul>
      <li parentName="ul">{`Code reviews are done as part of normal issue handling.`}</li>
      <li parentName="ul">{`Every pull request should be reviewed by at least one other developer.`}</li>
      <li parentName="ul">{`Any developer can (and should) do code reviews before taking on new work.`}</li>
      <li parentName="ul">{`After doing a review, you should ensure that it gets past acceptance and testing. This means notifying the ticket creator, service owner, or an end user and ensuring they can test that the feature works as it should.`}</li>
    </ul>
    <p><a parentName="p" {...{
        "href": "./accepting-contributions.md"
      }}>{`Accepting outsider contributions`}</a>{` has its own instructions.`}</p>
    <h2 {...{
      "id": "git-workflow"
    }}>{`Git Workflow`}</h2>
    <p>{`Branching model is `}<a parentName="p" {...{
        "href": "https://guides.github.com/pdfs/githubflow-online.pdf"
      }}>{`GitHub flow`}</a></p>
    <h3 {...{
      "id": "how-it-works"
    }}>{`How it Works`}</h3>
    <ul>
      <li parentName="ul">{`We only have `}<inlineCode parentName="li">{`master`}</inlineCode>{` and feature branches`}</li>
      <li parentName="ul">{`Test/staging env runs `}<inlineCode parentName="li">{`master`}</inlineCode>{` branch`}</li>
      <li parentName="ul">{`We merge the feature branches to `}<inlineCode parentName="li">{`master`}</inlineCode></li>
      <li parentName="ul">{`When we release, we create a tag to `}<inlineCode parentName="li">{`master`}</inlineCode>{` branch`}</li>
      <li parentName="ul">{`Feature flags are used to hide unfinished features to keep master deployable`}</li>
    </ul>
    <h4 {...{
      "id": "creating-a-release"
    }}>{`Creating a Release`}</h4>
    <ul>
      <li parentName="ul">{`Create a tag `}<inlineCode parentName="li">{`release-x.x.x`}</inlineCode>{` to `}<inlineCode parentName="li">{`master`}</inlineCode>{` branch`}</li>
    </ul>
    <h4 {...{
      "id": "creating-a-hotfix"
    }}>{`Creating a Hotfix`}</h4>
    <p>{`In general hotfixes should be avoided, but there can be emergency cases where a critical bug is found from production and for some reason `}<inlineCode parentName="p">{`master`}</inlineCode>{` is not deployable.`}</p>
    <ul>
      <li parentName="ul">{`Create a branch from release tag `}<inlineCode parentName="li">{`git checkout -b hotfix/release-x.x.x release-x.x.x`}</inlineCode></li>
      <li parentName="ul">{`Implement emergency fix to that branch (cherry pick from `}<inlineCode parentName="li">{`master`}</inlineCode>{` or make a new commit if you cannot cherry pick)`}<ul parentName="li">
          <li parentName="ul">{`If you cannot cherry pick, the fix needs to be implemented to `}<inlineCode parentName="li">{`master`}</inlineCode>{` as a new commit also`}</li>
        </ul></li>
      <li parentName="ul">{`Tag the hotfix branch to get it deployed, tag name: `}<inlineCode parentName="li">{`release-x.x.x-hotfix`}</inlineCode></li>
      <li parentName="ul">{`Never merge the hotfix branch (just leave it there or eventually delete it)`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      